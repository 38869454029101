import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import logo from '../assets/image/kami-ada-logo.png'
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from '../context/UserAuthContext';
import GoogleButton from 'react-google-button';

function Login () {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const {googleSignIn, login} = useUserAuth(); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
        await login(email, password)
        navigate("/")
    } catch (err) {
      setError(err.message);
    }
  }

  const googleButton = (e) => {
    googleSignIn();
    navigate("/");
  }

  return (
    <div className="form-container d-flex justify-content-center align-items-center position-relative">
      <Form className="form-login" onSubmit={handleSubmit}>
      <div className='close-signup' onClick={()=>{navigate("/")}}>&#10005;</div>
        <img className="logo-signup" height="60" src={logo} />
        <div className="title">Login</div>
        {error && <div className="error">{error}</div>}
        <Form.Group className="form-group-signup">
          <Form.Label className="form-label-signup">Email :</Form.Label>
          <Form.Control 
            type="text" 
            className="form-control-signup" 
            onChange={e=>setEmail(e.target.value)}
            required />
        </Form.Group> 
        <Form.Group className="form-group-signup">
          <Form.Label className="form-label-signup">Password :</Form.Label>
          <Form.Control 
            type="password" 
            className="form-control-signup"
            minLength="8"
            onChange={e=>setPassword(e.target.value)}
            required />
        </Form.Group>
        <Button variant="danger" className="form-btn-login" type="submit">Masuk</Button>
        <div className="d-flex justify-content-center align-items-center">
            <GoogleButton onClick={googleButton} className="btn-google" />
          </div>
        <div className="login-ask">Belum Punya Akun?</div>
        <Button variant="link" className="link-login" onClick={() => navigate("/signup")}>Klik Disini!</Button>
      </Form>
    </div>
  )
}

export default Login;

