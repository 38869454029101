import './App.css';
import { Routes, Route } from "react-router-dom"
import Signup from './components/Signup';
import Login from './components/Login';
import { UserAuthContextProvider } from './context/UserAuthContext';
import Home from './components/Home';
import Search from './components/Search';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  return (
    <UserAuthContextProvider>
      <Routes>
        <Route path='/' element={<Home />}/>  
        <Route path='/signup' element={<Signup />}/>
        <Route path='/login' element={<Login />}/>
        <Route path='/search' element={<ProtectedRoute><Search /></ProtectedRoute>}/>
      </Routes>
    </UserAuthContextProvider>
  );
}

export default App;
