import React, { useEffect, useState } from "react";
import Header from "./Header";
import { database } from "../firebase"
import {set, ref,  child, get} from "firebase/database"

function Search() {

  const searchItem = sessionStorage.getItem("searchItem") + "/";
  const searchItemTitle = sessionStorage.getItem("searchItem");

  useEffect(() => {
      get(child(ref(database), searchItem)).then((snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach(function(element){
            document.getElementById('searchitem').innerHTML +=`<div className="no-hp"><a href="tel:${element.val()}">${element.val()}</a></div>`;
          })
          console.log(searchItem)
        } else {
          console.log("No data available");
        }
}).catch((error) => {
  console.error(error);
});
  },[])
  return(
    <>
      <Header />
      <div className="container d-flex justify-content-center align-items-center position-relative">
        <div className="search-container">
          <div className="search-title">{searchItemTitle}</div>
          <div id="searchitem" className="grid-search-container"></div>
        </div>
      </div>
    </>
  )
}

export default Search;