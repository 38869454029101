import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database"

const firebaseConfig = {
  apiKey: "AIzaSyBngeKzcY7Pz9QR_ZDWPWuLZEJUmqz7KYM",
  authDomain: "kami-ada-668b7.firebaseapp.com",
  databaseURL: "https://kami-ada-668b7-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "kami-ada-668b7",
  storageBucket: "kami-ada-668b7.appspot.com",
  messagingSenderId: "988261887738",
  appId: "1:988261887738:web:357c78d611d81c9317e343",
  measurementId: "G-KYX85NKWD1"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const database = getDatabase(app)
export default app;