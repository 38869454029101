import React, {useState, useEffect} from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from '../context/UserAuthContext';
import { onAuthStateChanged } from "firebase/auth"
import { auth } from "../firebase"

const ProtectedRoute = ({ children }) => {
  const [user, setUser] = useState({})

  onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      console.log(user)
    });

  if(!user) {
    return <Navigate to="/login" />
  }
  return children
}

export default ProtectedRoute;