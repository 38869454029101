import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import logo from '../assets/image/kami-ada-logo.png'
import { useNavigate } from 'react-router-dom';
import { Alert } from "bootstrap";
import { useUserAuth } from '../context/UserAuthContext';

function Signup () {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState("");

  const {signUp} = useUserAuth(); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      if (password.valueOf()!==password2.valueOf()) {
        alert("Password Tidak Sama!")
      } else { 
        await signUp(email, password)
        navigate("/login")
      }
    } catch (err) {
      setError(err.message);
    }
  }

  return (
    <div className="form-container d-flex justify-content-center align-items-center position-relative">
      <Form className="form-signup" onSubmit={handleSubmit}>
      <div className='close-signup' onClick={()=>{navigate(-1)}}>&#10005;</div>
        <img className="logo-signup" height="60" src={logo} />
        <div className="title">Sign Up</div>
        {error && <div className="error">{error}</div>}
        <Form.Group className="form-group-signup">
          <Form.Label className="form-label-signup">Email :</Form.Label>
          <Form.Control 
            type="email" 
            className="form-control-signup"
            onChange={e=>setEmail(e.target.value)}
            required />
        </Form.Group> 
        <Form.Group className="form-group-signup">
          <Form.Label className="form-label-signup">Password :</Form.Label>
          <Form.Control 
            type="password" 
            className="form-control-signup" 
            minLength="8"
            onChange={e=>setPassword(e.target.value)}
            required />
        </Form.Group>
        <Form.Group>
          <Form.Label className="form-label-signup">Confirm Password :</Form.Label>
          <Form.Control 
            type="password" 
            className="form-control-signup"
            minLength="8"
            onChange={e=>setPassword2(e.target.value)}
            required />
        </Form.Group>
        <Button variant="danger" className="form-btn-signup" type="submit">Daftar</Button>
        <div className="login-ask">Sudah Punya Akun?</div>
        <Button variant="link" className="link-login" onClick={() => navigate("/login")}>Klik Disini!</Button>
      </Form>
    </div>
  )
}

export default Signup;

