import React from "react";
import Header from "./Header";
import homePic from '../assets/image/home-pic.jpeg'
import logo from '../assets/image/kami-ada-logo.png'
import wave from '../assets/image/wave.png'

function Home() {
  return(
    <>
      <Header />
      <div className="form-container d-flex justify-content-center align-items-center position-relative">
        <div className="home-item-container justify-content-center align-items-center position-relative">
          <img src={logo} className="home-img" />
          <div>
            <div className="home-title">KAMI ADA</div>
            <div className="home-tagline">Mempermudah Mencari Jasa</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home;