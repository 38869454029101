import React, {useState} from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, useNavigate, Route, Router} from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import logo from '../assets/image/kami-ada-logo.png'
import userPic from '../assets/image/user.png'
import DatalistInput from 'react-datalist-input';
import 'react-datalist-input/dist/styles.css';

function Header() {
  const [searchData, setSearch]=useState('');
  const [error, setError]=useState(false);
  const navigate = useNavigate();
  const {user, logout} = useUserAuth();

  const data=["Sumur Bor", "Sedot WC", "Service Kulkas", "Pusat Terapi"]

  return (
    <Navbar className="navbar-header" sticky="top">
      <div className="header-left">
        <img height="45" src={logo} onClick={()=>navigate("/")}/> 
        <Navbar.Brand className="logo-header" onClick={()=>navigate("/")}>Kami Ada</Navbar.Brand>
      </div>
      <div className="header-center">

      <DatalistInput
        placeholder="Pilih Yang Ingin Dicari"
        onSelect={(item) => {
          console.log(item.value)
          sessionStorage.setItem("searchItem", item.value);
          navigate("/search")
          window.location.reload(false)
        }}
        items={[
                { id: 'Sumur Bor', value: 'Sumur Bor' },
                { id: 'Sedot WC', value: 'Sedot WC' },
                { id: 'Service Kulkas', value: 'Service Kulkas' },
                { id: 'Pusat Terapi', value: 'Pusat Terapi' },
              ]}
      />
      </div >

      <div className="d-flex profil-container header-right">
        {user ? 
        <div>
          <div class="dropdown">
          <img src={userPic} className="profil-pic"/>
            <div class="dropdown-content">
              <div className="dropdown-item">{user.email}</div>
              <Button className="p-20 logout-btn" onClick={logout}>Log Out</Button>
            </div>
          </div>
        </div>:""}
        {!user ? 
        <div>
          <Button onClick={() => {navigate("/login")}}>Log In</Button>
        </div>:""}
      </div>
    </Navbar>
  );
}

export default Header;